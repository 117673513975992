.identity-picture{
    height: 100px;
    width: 100px;
}
.identity-picture img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-position: 0px -3px;
}

.nav{
    margin-top: 4em;
}

li{
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .name{
      flex-basis: auto;
      
    }
  }