.container {
    position: relative;
    border-radius: 100%;
    padding: 5px;
  }
  .spinner-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  }
  .preview-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 999;
  }

  .btn{
   border: 2px solid white;
   padding: 1em;
  }
  
  .preview-image {
    width: 100%;
    height: 80%;
    object-fit: contain;
  }

  .separateur{
    border-color: rgb(211, 26, 165);
  }

  .KASA{
    background-color: WHITE;
  }

  .OHMYFOOD{
    background: linear-gradient(#ff79da, #9356dc);
  }