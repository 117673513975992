 .welcome {
    display: inline-block;
    animation: sautille 0.5s;
  }
  
  @keyframes sautille {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .tooltip-content {
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
 
  }

  .tooltip-container {
    position: relative;
    
    cursor: pointer;
  }
  